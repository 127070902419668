import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import rvsecuredLogo from '../images/rvsecured-logo.png';

const LogoFloat = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoBand = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: rgba(206, 198, 172, 0.65);
  padding-top: 10px;
  padding-bottom: 10px;
  
`

export default function Home({ data }) {
    const backgroundSources = [
        data.mobileImage.childImageSharp.fluid,
        {
            ...data.desktopImage.childImageSharp.fluid,
            media: '(min-width: 768px)'
        }
    ];

    const logoSources = [
        data.mobileLogo.childImageSharp.fixed,
        {
            ...data.desktopLargeLogo.childImageSharp.fixed,
            media: '(min-width: 1000px)'
        }
    ];

    return (
        <Layout>
            <Img fluid={backgroundSources} />
            <LogoFloat>
                <LogoBand><Img fixed={logoSources} /></LogoBand>
            </LogoFloat>
        </Layout>
    );
}

export const query = graphql`
    query {
        mobileImage: file(relativePath: { eq: "rvsecured-mobile.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 850, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        desktopImage: file(relativePath: { eq: "rvsecured-full-page.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        mobileLogo: file(relativePath: { eq: "rvsecured-logo.png" }) {
            childImageSharp {
                fixed(width: 350, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        },
        desktopLogo: file(relativePath: { eq: "rvsecured-logo.png" }) {
            childImageSharp {
                fixed(width: 450, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        desktopLargeLogo: file(relativePath: { eq: "rvsecured-logo.png" }) {
            childImageSharp {
                fixed(width: 500, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
